export type Font = {
  name: string;
  type: string;
  group: string;
  weight: number;
};

export const fonts: Font[] = [
  {
    name: "Theinhardt-Hairline",
    type: "Hairline",
    group: "Theinhardt",
    weight: 100,
  },
  {
    name: "Theinhardt-Ultralight",
    type: "Ultralight",
    group: "Theinhardt",
    weight: 200,
  },
  {
    name: "Theinhardt-Thin",
    type: "Thin",
    group: "Theinhardt",
    weight: 300,
  },
  {
    name: "Theinhardt-Light",
    type: "Light",
    group: "Theinhardt",
    weight: 400,
  },
  {
    name: "Theinhardt-Regular",
    type: "Regular",
    group: "Theinhardt",
    weight: 500,
  },
  {
    name: "Theinhardt-Medium",
    type: "Medium",
    group: "Theinhardt",
    weight: 600,
  },
  {
    name: "Theinhardt-Bold",
    type: "Bold",
    group: "Theinhardt",
    weight: 700,
  },
  {
    name: "Theinhardt-Heavy",
    type: "Heavy",
    group: "Theinhardt",
    weight: 800,
  },
  {
    name: "Theinhardt-Black",
    type: "Black",
    group: "Theinhardt",
    weight: 900,
  },
  {
    name: "Theinhardt-Compact-Hairline",
    type: "Compact-Hairline",
    group: "Theinhardt-Compact",
    weight: 100,
  },
  {
    name: "Theinhardt-Compact-Ultralight",
    type: "Compact-Ultralight",
    group: "Theinhardt-Compact",
    weight: 200,
  },
  {
    name: "Theinhardt-Compact-Thin",
    type: "Compact-Thin",
    group: "Theinhardt-Compact",
    weight: 300,
  },
  {
    name: "Theinhardt-Compact-Light",
    type: "Compact-Light",
    group: "Theinhardt-Compact",
    weight: 400,
  },
  {
    name: "Theinhardt-Compact-Regular",
    type: "Compact-Regular",
    group: "Theinhardt-Compact",
    weight: 500,
  },
  {
    name: "Theinhardt-Compact-Medium",
    type: "Compact-Medium",
    group: "Theinhardt-Compact",
    weight: 600,
  },
  {
    name: "Theinhardt-Compact-Bold",
    type: "Compact-Bold",
    group: "Theinhardt-Compact",
    weight: 700,
  },
  {
    name: "Theinhardt-Compact-Heavy",
    type: "Compact-Heavy",
    group: "Theinhardt-Compact",
    weight: 800,
  },
  {
    name: "Theinhardt-Condensed-Hairline",
    type: "Condensed-Hairline",
    group: "Theinhardt-Condensed",
    weight: 100,
  },
  {
    name: "Theinhardt-Condensed-Ultralight",
    type: "Condensed-Ultralight",
    group: "Theinhardt-Condensed",
    weight: 200,
  },
  {
    name: "Theinhardt-Condensed-Thin",
    type: "Condensed-Thin",
    group: "Theinhardt-Condensed",
    weight: 300,
  },
  {
    name: "Theinhardt-Condensed-Light",
    type: "Condensed-Light",
    group: "Theinhardt-Condensed",
    weight: 400,
  },
  {
    name: "Theinhardt-Condensed-Regular",
    type: "Condensed-Regular",
    group: "Theinhardt-Condensed",
    weight: 500,
  },
  {
    name: "Theinhardt-Condensed-Medium",
    type: "Condensed-Medium",
    group: "Theinhardt-Condensed",
    weight: 600,
  },
  {
    name: "Theinhardt-Condensed-Bold",
    type: "Condensed-Bold",
    group: "Theinhardt-Condensed",
    weight: 700,
  },
  {
    name: "Theinhardt-Condensed-Heavy",
    type: "Condensed-Heavy",
    group: "Theinhardt-Condensed",
    weight: 800,
  },
  {
    name: "Theinhardt-Condensed-Black",
    type: "Condensed-Black",
    group: "Theinhardt-Condensed",
    weight: 900,
  },
  {
    name: "Theinhardt-Extended-Hairline",
    type: "Extended-Hairline",
    group: "Theinhardt-Extended",
    weight: 100,
  },
  {
    name: "Theinhardt-Extended-Ultralight",
    type: "Extended-Ultralight",
    group: "Theinhardt-Extended",
    weight: 200,
  },
  {
    name: "Theinhardt-Extended-Thin",
    type: "Extended-Thin",
    group: "Theinhardt-Extended",
    weight: 300,
  },
  {
    name: "Theinhardt-Extended-Light",
    type: "Extended-Light",
    group: "Theinhardt-Extended",
    weight: 400,
  },
  {
    name: "Theinhardt-Extended-Regular",
    type: "Extended-Regular",
    group: "Theinhardt-Extended",
    weight: 500,
  },
  {
    name: "Theinhardt-Extended-Medium",
    type: "Extended-Medium",
    group: "Theinhardt-Extended",
    weight: 600,
  },
  {
    name: "Theinhardt-Extended-Bold",
    type: "Extended-Bold",
    group: "Theinhardt-Extended",
    weight: 700,
  },
  {
    name: "Theinhardt-Extended-Heavy",
    type: "Extended-Heavy",
    group: "Theinhardt-Extended",
    weight: 800,
  },
  {
    name: "Theinhardt-Extended-Black",
    type: "Extended-Black",
    group: "Theinhardt-Extended",
    weight: 900,
  },
  {
    name: "Theinhardt-Mono-Thin",
    type: "Mono-Thin",
    group: "Theinhardt-Mono",
    weight: 300,
  },
  {
    name: "Theinhardt-Mono-Light",
    type: "Mono-Light",
    group: "Theinhardt-Mono",
    weight: 400,
  },
  {
    name: "Theinhardt-Mono-Regular",
    type: "Mono-Regular",
    group: "Theinhardt-Mono",
    weight: 500,
  },
  {
    name: "Theinhardt-Mono-Medium",
    type: "Mono-Medium",
    group: "Theinhardt-Mono",
    weight: 600,
  },
  {
    name: "Theinhardt-Mono-Bold",
    type: "Mono-Bold",
    group: "Theinhardt-Mono",
    weight: 700,
  },
  {
    name: "Theinhardt-Mono-Heavy",
    type: "Mono-Heavy",
    group: "Theinhardt-Mono",
    weight: 800,
  },
  {
    name: "Theinhardt-SemiCondensed-Hairline",
    type: "SemiCondensed-Hairline",
    group: "Theinhardt-SemiCondensed",
    weight: 100,
  },
  {
    name: "Theinhardt-SemiCondensed-Ultralight",
    type: "SemiCondensed-Ultralight",
    group: "Theinhardt-SemiCondensed",
    weight: 200,
  },
  {
    name: "Theinhardt-SemiCondensed-Thin",
    type: "SemiCondensed-Thin",
    group: "Theinhardt-SemiCondensed",
    weight: 300,
  },
  {
    name: "Theinhardt-SemiCondensed-Light",
    type: "SemiCondensed-Light",
    group: "Theinhardt-SemiCondensed",
    weight: 400,
  },
  {
    name: "Theinhardt-SemiCondensed-Regular",
    type: "SemiCondensed-Regular",
    group: "Theinhardt-SemiCondensed",
    weight: 500,
  },
  {
    name: "Theinhardt-SemiCondensed-Medium",
    type: "SemiCondensed-Medium",
    group: "Theinhardt-SemiCondensed",
    weight: 600,
  },
  {
    name: "Theinhardt-SemiCondensed-Bold",
    type: "SemiCondensed-Bold",
    group: "Theinhardt-SemiCondensed",
    weight: 700,
  },
  {
    name: "Theinhardt-SemiCondensed-Heavy",
    type: "SemiCondensed-Heavy",
    group: "Theinhardt-SemiCondensed",
    weight: 800,
  },
  {
    name: "Theinhardt-SemiCondensed-Black",
    type: "SemiCondensed-Black",
    group: "Theinhardt-SemiCondensed",
    weight: 900,
  },
  {
    name: "Theinhardt-SemiExtended-Hairline",
    type: "SemiExtended-Hairline",
    group: "Theinhardt-SemiExtended",
    weight: 100,
  },
  {
    name: "Theinhardt-SemiExtended-Ultralight",
    type: "SemiExtended-Ultralight",
    group: "Theinhardt-SemiExtended",
    weight: 200,
  },
  {
    name: "Theinhardt-SemiExtended-Thin",
    type: "SemiExtended-Thin",
    group: "Theinhardt-SemiExtended",
    weight: 300,
  },
  {
    name: "Theinhardt-SemiExtended-Light",
    type: "SemiExtended-Light",
    group: "Theinhardt-SemiExtended",
    weight: 400,
  },
  {
    name: "Theinhardt-SemiExtended-Regular",
    type: "SemiExtended-Regular",
    group: "Theinhardt-SemiExtended",
    weight: 500,
  },
  {
    name: "Theinhardt-SemiExtended-Medium",
    type: "SemiExtended-Medium",
    group: "Theinhardt-SemiExtended",
    weight: 600,
  },
  {
    name: "Theinhardt-SemiExtended-Bold",
    type: "SemiExtended-Bold",
    group: "Theinhardt-SemiExtended",
    weight: 700,
  },
  {
    name: "Theinhardt-SemiExtended-Heavy",
    type: "SemiExtended-Heavy",
    group: "Theinhardt-SemiExtended",
    weight: 800,
  },
  {
    name: "Theinhardt-SemiExtended-Black",
    type: "SemiExtended-Black",
    group: "Theinhardt-SemiExtended",
    weight: 900,
  },
];
