import { Animator, Step, Timeline } from "optimo-animator";
import {
  clamp,
  getFontGroup,
  getFontGroupWeights,
  pickRandom,
  setCss,
} from "./utils";
import { Writer } from "./Writer";
import { Font, fonts } from "./fonts";

enum Actions {
  Start = "start",
  Stop = "stop",
  Reset = "reset",
}

const MAX_RATIO = 2.4;
const MID_RATIO = 1.7;

const isMid = () => {
  return (
    window.innerWidth / window.innerHeight >= MID_RATIO &&
    window.innerWidth / window.innerHeight < MAX_RATIO
  );
};

const isMobile = () => {
  return window.innerWidth / window.innerHeight < MID_RATIO;
};

(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const delay = urlParams.get("delay") === "true";

  const texts = ["The Original", "Grotesque", "Theinhardt"];
  const lines = Array.from(document.querySelectorAll(".line")) as HTMLElement[];
  const writers = lines.map((line) => new Writer(line));
  let lastWeight: number = Math.floor(Math.random() * 4) * 100;

  const setLineByIndex = (index: number) => {
    const fontSet =
      isMid() || isMobile()
        ? fonts.filter(
            (it) =>
              ![
                "Theinhardt-Extended",
                "Theinhardt-Mono",
                "Theinhardt-SemiExtended",
              ].includes(it.group)
          )
        : fonts;

    // const randomFont = fonts.find(
    //   (it) => it.name === "Theinhardt-Extended-Regular"
    // ) as Font;

    const randomFont = pickRandom(fontSet);
    const fontGroup = getFontGroup(randomFont.group, fontSet);
    const groupWeights = getFontGroupWeights(fontGroup);
    const maxWeight = Math.max(...groupWeights);
    const minWeight = Math.min(...groupWeights);
    const weight = (lastWeight % maxWeight) + 100;

    setCss(lines[index], {
      fontFamily: randomFont.group,
      fontWeight: `${clamp(weight, minWeight, maxWeight)}`,
    });

    lastWeight = weight + 400;
  };

  writers.forEach((writer, index) => {
    writer.setText(texts[index]);
    setLineByIndex(index);
  });

  const steps: Step[] = writers.map((writer, index) => ({
    duration: 1000,
    onStart() {
      writer.reset();
      setLineByIndex(index);
    },
    handler(progress) {
      writer.render(progress);
    },
  }));

  const timeline = new Timeline(steps);
  const animator = new Animator([timeline]);

  if (delay) {
    animator.stop();
    animator.startTime = 0;
    animator.computeFrameAt(0);
    window.addEventListener(
      "message",
      ({ data }) => {
        const { action } = data;
        if (action === Actions.Start) {
          animator.start();
        } else if (action === Actions.Stop) {
          animator.stop();
        } else if (action === Actions.Reset) {
          animator.startTime = 0;
          animator.computeFrameAt(0);
        }
      },
      false
    );
  } else {
    animator.start();
  }

  const updateIsMobile = () => {
    const lines = document.querySelector(".lines");

    if (isMobile()) {
      lines?.classList.add("is-mobile");
    } else {
      lines?.classList.remove("is-mobile");
    }

    if (isMid()) {
      lines?.classList.add("is-mid");
    } else {
      lines?.classList.remove("is-mid");
    }
  };

  updateIsMobile();
  window.addEventListener("resize", updateIsMobile);
  // document.addEventListener("visibilitychange", () => {
  //   if (document.hidden) {
  //     animator.stop();
  //   } else {
  //     animator.start();
  //   }
  // });
})();
