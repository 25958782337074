import { Font } from "./fonts";

export const setCss = (
  element: HTMLElement,
  style: Partial<CSSStyleDeclaration>
) => {
  for (const [key, value] of Object.entries(style)) {
    element.style[key] = value;
  }
};

export const pickRandom = <T>(array: T[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

export const getFontGroup = (group: string, fonts: Font[]) => {
  return fonts.filter((font) => font.group === group);
};

export const getFontGroupWeights = (fonts: Font[]) => {
  return fonts.map((font) => font.weight);
};

export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);
